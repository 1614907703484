<template>
  <div class="footer-bar padding-10">
    <template>
      <div @click="toPage('/')" v-if="$baseInfo.IS_CF !== 1">
        <img src="../assets/image/foot_11.png" alt="" />
        <div>首页</div>
      </div>
      <div @click="toPage('/fee-compute')" v-if="$baseInfo.IS_CF === 1">
        <img src="../assets/image/foot_14.png" alt="" />
        <div>费用估算</div>
      </div>
    </template>

    <div @click="toPage('/user-center')">
      <img src="../assets/image/foot_12.png" alt="" />
      <div>
        <template v-if="$baseInfo.IS_CF === 1"> 会员中心 </template>
        <template v-else> 国际集运会员 </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "footerBar",
  methods: {
    toPage(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
.footer-bar {
  font-size: 15px;
  color: #0cb1f3;

  border-top: 2px solid #dddddd;
}
.footer-bar img {
  width: 25px;
}
</style>